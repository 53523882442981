import * as React from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import CoachCard from '../components/CoachCard';
import AvatarMenu from '../components/AvatarMenu';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = ({data}) => {
  const theme = useTheme();
  const xsScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const [coach, setCoach] = React.useState('');

  const structuredData = '';

  return (
  <Layout>
    <SEO title="About" structuredData={structuredData}/>
    <Grid container direction="column" alignItems="center" style={{textAlign: 'center'}}>
      <Grid item style={{padding: '2rem', paddingBottom: '1rem'}} xs={12}>
        <Typography variant="h3" style={{color: '#243E83'}}>Who we are and What we do</Typography>
      </Grid>
      <Grid item xs={12} sm={8} style={{padding: '2rem'}}>
        <Typography variant="paragraph">
          We founded Blue Crabs Volleyball to fill a gap in the beach volleyball programs around the DMV area that could really
          result in athletes finding a passion for the beach game and opening them to new opportuniies and
          experiences in the volleyball world.<br></br>
          We truly  believe that this sport can be for everyone, and that athletes all learn differently. Because of this we put
          a strong emphasis on personalized coaching experiences so that no ahlete who wants to improve and enjoy the game has to
          feel like they just can't get better or keep up. In addition, our philosophy is that one of the best ways to improve is to 
          dive in and be exposed to higher level play; as such, our clinics and drills involve a lot of gameplay and coaches on the couurt.
          All of us really believe and have personally experienced how with drive and
          the right guidance, all goals are within reach. It is our hope to pass some of this passion onto the athletes we'll be 
          working with this season. We also really believe that volleyball should be fun, and that's a big part of playing with our club!
          <br></br>If you're looking for an environment to enjoy the game and while having fun learn in new ways so that you can 
          break into a new tier of play, guided by experienced and passionate coaches, then welcome!
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8} syle={{padding: '2rem'}}>
      <Typography variant="h6" style={{color: '#243E83', borderBottom: '1px solid #F78D1F', marginBottom: '15px'}}><Link to="/clinics">We hope to see you on the court!</Link></Typography>
      </Grid>
    </Grid>
  </Layout>
)}

export default AboutPage